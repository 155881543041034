<template>
  <div class="infoPanel">
    <div class="f_flex f_a_c f_j_sb">
      <div class="f_flex f_a_c">
        <img class="iconInfo" src="../../../assets/infoCenter.png" alt="">
        <div class="title">{{title}}</div>
      </div>
      <div class="date">{{date}}</div>
    </div>
    <div class="content">
      {{content}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    date: String,
    content: String
  }
}
</script>

<style lang="less" scoped>
.infoPanel {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  padding: 16px 12px;
  margin-bottom: 13px;
  // &:active {
  //   background: rgba(200, 200, 200, 0.01);
  // }
}
.iconInfo {
  width: 32px;
  height: 32px;
  margin-right: 11px;
}
.title {
  color: #434343;
  font-size: 16px;
  font-weight: 500;
}
.date {
  font-size: 13px;
  color: #9B9B9B;
}
.content {
  font-size: 14px;
  line-height: 20px;
  margin-top: 15px;
  color: #2D2D2D;
  text-align: justify;
}
</style>