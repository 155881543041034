<template>
  <page :styleWrap='{background: "#f6f6f6"}' ref='wrap'>
    <div class="infoCenterpage" ref='content'>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div style="height: 20px"></div>
      <div v-if='result.length'>
        <info-panel
          v-for='(item, index) in result'
          :key='index'
          :title='item.title' 
          :date='item.pushTime' 
          :content='item.content'>
        </info-panel>
      </div>
      <empty description="没有消息哦~" v-else>
        <van-button round type="danger" @click="goBack" class="bottom-button">
          返回
        </van-button>
      </empty>
    </div>
  </page>
</template>

<script>
import infoPanel from './components/infoPanel.vue'
import otherApi from '@/api/other'
import scroll from '@/common/mixins/scroll'
import { Empty, Button } from 'vant';

export default {
  components: {
    'info-panel': infoPanel,
    'van-button': Button,
    Empty
  },
  data: ()=> ({
    pageSize: 20,
    pageNo: 1,
    isEnd: false,
    result: []
  }),
  mixins: [scroll],
  methods: {
    getList() {
      const companyInfo = this.$global.getItem('companyInfo', true)
      if (!this.isEnd) {
        const obj = {
          pageSize: this.pageSize,
          pageNo: this.pageNo
        }
        if (companyInfo) {
          obj.companyId = companyInfo.companyId
        }
        otherApi.noticeList(obj).then(res => {
          if (res.data) {
            this.result.push(...res.data.noticeList)
            if (this.isEnd) {
              this.isEnd = true
            } else {
              this.pageNo++
            }
          }
        })
      }
    },
    goBack() {
      this.$router.back()
    },
    init() {
      this.pageNo = 1
      this.result = []
      this.isEnd = false
    }
  },
  mounted() {
    this.getList()
    this.$route.meta.change = ()=> {
      this.init()
      this.getList()
    }
    const wrap = this.$refs.wrap.$refs.pageContent_contain
    const content = this.$refs.content
    this.onContentScroll(wrap, content, this.isEnd, ()=> {
      this.getList()
    })
  },
}
</script>

<style lang="less" scoped>
.infoCenterpage {
  position: relative;
  padding: 16px 15px;
  padding-top: 0;
}
.bottom-button {
  width: 200px
}
</style>